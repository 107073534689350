@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(../assests/fonts/Gilroy-Medium.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  background-color: black;
  text-align: center;
  font-family: Gilroy-Medium;
  padding: 0;
  margin: 0;
}

body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1; 
  content:url(../assests/images/3840x2160-01.jpg) url(../assests/images/3840x2160-02.jpg);   
}

/* Modal */

.parent-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  height: 98%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  border-radius: 0.5vw
}

.feedback-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  border-radius: 0.5vw
}

.parent-modal>*{
  font-family: Gilroy-Medium !important;
}

#modal-modal-title{
  margin-right: 40%;
}
#modal-modal-title>span{
  font-size: 1.7rem !important;
  color:#ffffff;
}

.css-1sra7t5-MuiTypography-root{
  font-size: 1.7rem !important;
}

.close-button{
  height: 3.819vw;
  width: 3.819vw;
  position: absolute !important;
  top: 0 !important;
  right: 1.944vw !important;
  cursor: pointer;

}

.close-button-ar{
  height: 3.819vw;
  width: 3.819vw;
  position: absolute !important;
  top: 0 !important;
  left: 1.944vw !important;
  cursor: pointer;

}

.download-button{
  height: 3.819vw;
  width: 3.819vw;
  position: absolute !important;
  top: 11.6vw !important;
  right: 5vw !important;
  cursor: pointer;
}

.download-button-ar{
  height: 3.819vw;
  width: 3.819vw;
  position: absolute !important;
  top: 11vw !important;
  left: 9vw;
  cursor: pointer;

}

.close-icon{
  color:#ffffff ;
  background-color:black;
  border-radius:100%;
}
.download-icon{
  color:black ;
  background-color:white;
  border-radius:100%;
  border: 2px solid black;


}

.modal-button:hover{
  background-color:lightgray !important;
  color:#3FA796 !important;
  font-weight:bold !important;
}

.modal-button-ar:hover{
  background-color:lightgray !important;
  color:#3FA796 !important;
  font-weight:bold !important;
}

.button-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-evenly;
}

.button-container-ar{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-evenly;
  transform: scaleX(-1)
}
.modal-button{
  background-color:#ffffff !important;
  color:#3FA796 !important;
  font-weight:bold !important;;
}

.modal-button-ar{
  background-color:#ffffff !important;
  color:#4CAF51 !important; 
  font-weight:bold !important;
  transform: scaleX(-1)
}

.modal-header-container{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: flex-end;
}

.modal-header-container-ar{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: flex-end;
  margin-top: 1%;
  transform: scaleX(-1);
}

#modal-modal-title-ar{
  margin-bottom: 1%;
  margin-right: 43%;
  transform: scaleX(-1);
}

#modal-modal-title-ar>span{
  font-size: 1.7rem !important;
  color:#ffffff;
}
/* end Modal */


/* Home page  */
.HomePage {
  background-image: url("../assests/images/Group13937.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px;
  padding-bottom: 15px;
  opacity: 1;
  position: relative;
}

.touch {
  display: block;
}
.bgVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.HomePageMob {
  padding: 5px;
  padding-bottom: 15px;
  opacity: 1;
  z-index: 1;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 3rem 4rem 0rem 4rem;
}

.mainTitle {
  margin: 0 3.5% 0;
  font-size: 95px;
  color: #ffffff;
}
.mainTitle p {
  margin: 0 !important;
}
.tabContent {
  font-size: 45px;
  margin: 0 4% 0 4%;
  color: #ffffff;
}
.tabIcon {
  font-size: 45px;
  color: #ffffff;
  margin: 0 4.5% 0;
}
.items {
  display: flex;
  justify-content: space-between;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 1rem;
  align-items: flex-end;
}

.itemTitle {
  color: #ffffff;
  font-size: 24px;
  font-family: Gilroy-Medium;
  margin-bottom: 2rem;
}
.BottomSubTitle {
  color: white;
  font-size: 24px;
}

.itemSubtitle {
  color: #ffffff;
  font-size: 25px;
  margin-top: 1rem;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.cardData {
  border-radius: 25px;
  height: 100%;
  background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.325), rgb(0, 0, 0) );
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: space-between;
  align-items: flex-start;
}
.nullIcons {
  margin-bottom: 1rem;
}

.NotNullIcons {
  /* margin-top: 6rem; */
  margin-bottom: 1rem;
}

.card1 {
  height: 300px;
  background-image: url("../assests/images/Map.jpg");
  background-size: cover;
  width: 31%;
  border-radius: 25px;
  opacity: 1;
  cursor: pointer;
}
.card2 {
  height: 300px;
  background-image: url("../assests/images/G2.png");
  background-size: cover;
  width: 31%;
  border-radius: 25px;
  opacity: 1;
  cursor: pointer;
}
.card3 {
  background-image: url("../assests/images/Ex.jpg");
  background-size: cover;
  width: 31%;
  height: 300px;
  border-radius: 25px;
  opacity: 1;
  cursor: pointer;
}
.logoDiv {
  display: flex;
  justify-content: space-between;
}
.back {
  color: #65657b;
  font-size: 17px;
  margin-top: 1.5rem;
  margin-left: 4rem;
  margin-right: 4rem;
  cursor: pointer;
}
/* Lobby */
.gridCon {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 15px;
}
.links {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.GlobleIcon {
  margin-top: 1rem;
  display: inline-flex;
  cursor: pointer;
}

.IconSpan {
  margin: 5px 10px 0;
  color: white;
  font-size: 20px;
}
.Lobbybody {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  text-align: center;
  padding: 1rem;
  height: 75vh;
}

.form {
  background-color: rgb(236, 237, 241);
  border-radius: 30px;
  box-sizing: border-box;
  width: 350px;
  padding: 2rem 20px 2rem 20px;
}

.title {
  color: #65657b;
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 1rem;
}

.subtitle {
  color: #65657b;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
}

.ic1 {
  margin-top: 20px;
}

.ic2 {
  margin-top: 35px;
}

.input {
  background-color: white;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #474b7c;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
  font-family: sans-serif;
}

.cut {
  background-color: rgb(236, 237, 241);
  border-radius: 10px;
  height: 20px;
  /* left: 20px; */
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 92px;
}

.cut-short {
  width: 60px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  /* left: 20px; */
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
  font-size: 1.3rem;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #65657b;
}

.input:focus ~ .placeholder {
  color: #65657b;
}

.submit {
  background-color: rgb(2, 195, 154);
  border-radius: 50px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: black;
}

.error {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.4rem;
}

.button {
  display: inline-block;
  border-radius: 50px;
  background-color: rgb(2, 195, 154);
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 10px;
  width: 24%;
  transition: all 0.5s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

/* Participant */
.videoDiv {
  width: 92%;
  border-radius: 25px;
}

.videoDivOnShare {
  width: 65%;
  margin-bottom: 2rem;
  border-radius: 25px;
}
/* Room popup */
.video {
  display: inherit;
  color: white;
}
.margin {
  margin-bottom: 1rem;
}
.OnShare {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(191 191 191 / 70%);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  text-align: center;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 30px;
  width: 27%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 10px;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 0px;
  right: 12px;
  transition: all 200ms;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  border: none;
  background-color: transparent;
}
.popup .close:hover {
  color: #08d;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}
.Counter-info {
  text-align: center;
}
/* Room */
.Roomform {
  background-color: rgb(236, 237, 241);
  border-radius: 30px;
  box-sizing: border-box;
  padding: 20px;
  width: 382px;
}
.RoomMain {
  display: flex;
  justify-content: space-around;
  grid-gap: 2rem;
  text-align: center;
}
.Roomtitle {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 36px;
  font-weight: 600;
  /* margin-bottom: 2rem; */
}
.badge {
  color: white;
  font-size: 25px;
  position: absolute;
}
.Waiting {
  color: white;
  font-size: 20px;
}
.room {
  display: flex;
  justify-content: space-between;
  grid-gap: 0rem;
}

.roomButton {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.mainDiv {
  height: 90vh;
}
/* phone sizing */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .NotNullIcons {
    /* margin-top: 2rem; */
    margin-bottom: 1rem;
  }
  .Lobbybody {
    margin-top: 0;
  }
  .headerLang {
    margin-bottom: 2rem;
  }
  .room {
    display: inline-block;
  }
  .button {
    width: 76%;
  }

  .mainTitle {
    margin: 3.5% 3.5% 0 3.5%;
    font-size: 70px;
    color: #ffffff;
    text-align: center;
  }

  .mainTitle p {
    margin: 0 !important;
    font-size: 132px;
  }
  .tabIcon {
    text-align: center;
  }
  .logoDiv {
    display: block;
  }

  .form {
    border-radius: 20px;
    box-sizing: border-box;
    height: auto;
    padding-bottom: 3rem;
    width: auto;
  }
  .RoomMain {
    display: inline-block;
    text-align: center;
  }
  .button span:after {
    content: "";
    position: "";
    opacity: 0;
    top: 0;
    right: 0px;
  }
  .button:hover span {
    padding-right: 0px;
  }
  .local-participant {
    margin: 0 0 2rem 0;
  }
  .Roomtitle {
    font-size: 28px;
  }
  .videoDiv {
    width: 60%;
  }
  .popup {
    width: 65%;
  }
  .OnShare {
    display: block;
  }
  .videoDivOnShare {
    margin-bottom: 0rem;
    border-radius: 25px;
    height: 150px;
  }
  p {
    overflow: auto;
  }
  .main {
    text-align: center !important;
    height: auto;
  }
  /* home */

  .header {
    display: block;
    text-align: center;
    padding: 3rem 0rem 0rem 0rem;
  }

  .tabContent {
    font-size: 36px;
    text-align: center;
  }
  .items {
    display: block;
    position: relative;
  }
  .cardData {
    height: 255px;
    width: 300px;
  }
  .card1 {
    height: 255px;
    width: 300px;
    margin-bottom: 1rem;
  }
  .card2 {
    height: 255px;
    width: 300px;
    margin-bottom: 1rem;
  }
  .card3 {
    height: 255px;
    width: 300px;
    margin-bottom: 1rem;
  }
  .itemTitle {
    font-size: 18px;
  }
  .itemSubtitle {
    font-size: 22px;
  }
  /* end homepage */

  /* Modal */

  .parent-modal{
    background-color: #fff;
    width: 95%;
  }

  .parent-modal>*{
    font-family: Gilroy-Medium !important;
  }

  .css-1sra7t5-MuiTypography-root{
    font-family: Gilroy-Medium !important;
    width: 10% !important;
  }

  #modal-modal-title>span{
    font-size: 1.2rem !important;
    color:#ffffff;
  }

  #modal-modal-title-ar>span{
    font-size: 1.3rem !important;
    color:#ffffff;
  }
  #modal-modal-title{
    width: 55% !important;
  }

  #modal-modal-title-ar {
    margin-bottom: 1%;
    width: 55% !important;
    margin-right: 54%;
    transform: scaleX(-1);
  }

  .css-1sra7t5-MuiTypography-root{
    font-size: 1.2rem !important;
  }


  /* .close-button{
    flex-direction: row;
    height:  15%;
    width: 15%;
  } */

  .close-button{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 1% !important;
    right: 5.3%  !important;
  }
  .download-button{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 26% !important;
    right: 0% !important;
    cursor: pointer;
    

  }
  .download-button-ar{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 25% !important;
    left: 0% !important;
    cursor: pointer;

  }
  
  .close-button-ar{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 1% !important;
    left: 5.3% !important;
    cursor: pointer;

  }

  #child-modal-title{
    font-size: 1.2rem !important;
  }

  #child-modal-title-ar{
    font-size: 1.2rem !important;
  }
  
  .close-icon{
    color:#fff ;
    background-color:black;
    border-radius:100%;
    font-size: 100% !important;
  }
  .download-icon{
    color:black ;
    background-color:white;
    border-radius:100%;
    font-size: 100% !important;
    border: 2px solid black;

  }


  .child-modal{
    width: 70% !important;
  }

  #iframe{
    width: 100%;
    height: 100%;
  }

  /* end Modal */

}

@media only screen and (max-width: 1268px) {
  .itemSubtitle {
    font-size: 30px;
  }
  .touch {
    display: flex;
    justify-content: flex-start;
  }
  .tabContent {
    margin: 0;
    width: 100%;
    font-size: 30px;
  }
  .BottomSubTitle {
    font-size: 19px;
  }
  .child-modal{
    min-width: 300px;
  }
  .download-icon{
    color:black ;
    background-color:white;
    border-radius:100%;
    font-size: 83% !important;
  }
  .close-icon{
    color:#fff ;
    background-color:black;
    border-radius:100%;
    font-size: 83% !important;
  }
  
  .close-button{
    width: 9%;
    height: 4%;
    position: absolute !important;
    top: 2% !important;
    right: 5.3%  !important;
    padding: 0 !important;
  }
  .download-button{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 26% !important;
    right: 0% !important;
    cursor: pointer;
    

  }
  .download-button-ar{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 25% !important;
    left: 0% !important;
    cursor: pointer;

  }
  
  .close-button-ar{
    width: 9%;
    height: 2%;
    position: absolute !important;
    top: 2% !important;
    left: 5.3% !important;
    cursor: pointer;

  }

}


